import { observer } from 'mobx-react';
import { Banner } from '.';
import { Space, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import telegram from '../../assets/icons/telegram-icon.svg';

const telegramBlue = '#24A1DE';

export const TelegramChannelBanner = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
  }: {
    maxWidth?: string;
    padding?: string;
  }) => {
    const { t } = useTranslation('banner');

    return (
      <Banner padding={padding} maxWidth={maxWidth} color={telegramBlue}>
        <Text>{t('telegram.text1')}</Text>
        <Text>{t('telegram.text2')}</Text>
        <Space h={'2em'} />
        <div style={{ display: 'flex', gap: '1em' }}>
          <Text>{t('telegram.joinPrompt')}: </Text>
          <Link to={'https://t.me/scr_swiss'} target={'_blank'}>
            <img
              height={'20px'}
              width={'20px'}
              src={telegram}
              alt="Telegram icon"
              style={{ color: '#fff' }}
            />
          </Link>
        </div>
      </Banner>
    );
  },
);
